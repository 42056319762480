<template>
   <v-list-group tag="span" :color="selectedColor" :value="item.children.some((x) => isActive(x))">
      <template v-slot:activator>
         <v-tooltip right>
            <template v-slot:activator="{ on, attrs }">
               <v-list-item-icon v-bind="attrs" v-on="on">
                  <v-icon :color="selectedColor">{{ item.icon }}</v-icon>
               </v-list-item-icon>
            </template>
            <span>{{ translateKey(item.tooltipKey, translations) }}</span>
         </v-tooltip>

         <v-list-item-content :ui-test-data="item.uiSelector">
            <v-list-item-title>
               {{ translateKey(item.labelKey, translations) }}
            </v-list-item-title>
         </v-list-item-content>
      </template>

      <component
         v-for="child in item.children"
         :is="isGroup(child) ? 'nav-item-group-component' : 'nav-item-leaf-component'"
         :key="child.uiSelector"
         :item="child"
         :selected-color="selectedColor"
         :translations="translations"
         :is-active="isActive"
      />
   </v-list-group>
</template>

<script lang="ts">
import { Component, Prop } from "vue-property-decorator";
import { TranslationPublicModel } from "@backend/api/pmToolApi";
import ComponentBase from "@components/Shared/Base/component-base.vue";
import { NavItem, NavItemGroup, isGroup } from "@components/NavMenu/navItems";
import NavItemLeafComponent from "@components/NavMenu/nav-item-leaf.vue";

@Component({
   name: "NavItemGroupComponent",
   components: {
      NavItemLeafComponent,
   },
})
export default class NavItemGroupComponent extends ComponentBase {
   @Prop({ required: true })
   item: NavItemGroup;

   @Prop({ required: true })
   selectedColor: string;

   @Prop({ required: true })
   translations: TranslationPublicModel[];

   @Prop({ required: true })
   isActive: (x: NavItem) => boolean;

   isGroup = isGroup;
}
</script>

<style scoped>
.v-list-group--active {
   background-color: var(--v-bg240);
}
</style>
