<template>
   <div v-if="!loading && contentBrick">
      <div id="printable" class="container">
         <!-- Title -->
         <div class="row mb-5">
            <div class="col">
               <h4>
                  <span>{{ contentBrick.code }}</span>
                  <span>{{ contentBrick.name }}</span>
               </h4>
            </div>
         </div>

         <!-- Description -->
         <div class="row mb-5">
            <div class="col">
               <div v-html="contentBrick.description?.value"></div>
            </div>
         </div>

         <!-- Tags -->
         <div class="row mb-5" v-if="contentBrick.tags && contentBrick.tags.length > 0">
            <div class="col">
               <export-pdf-design-guideline-tags
                  :contentBrick="contentBrick"
                  :translations="routeTranslations"
               ></export-pdf-design-guideline-tags>
            </div>
         </div>

         <!-- Fields -->
         <div class="row mb-5">
            <div class="col">
               <export-pdf-design-guideline-fields
                  :contentBrick="contentBrick"
                  :translations="routeTranslations"
                  :hasValueFields="hasValueFields"
                  @loaded="print"
                  :mediaUrls="mediaUrls"
               ></export-pdf-design-guideline-fields>
            </div>
         </div>

         <!-- Metadata -->
         <div v-if="hasValueFields" class="row mb-5">
            <div class="col">
               <export-pdf-design-guideline-metadata
                  :contentBrick="contentBrick"
                  :translations="routeTranslations"
               ></export-pdf-design-guideline-metadata>
            </div>
         </div>

         <!-- History -->
         <div class="row mb-5">
            <div class="col">
               <export-pdf-design-guideline-history
                  :contentBrick="contentBrick"
                  :translations="routeTranslations"
               ></export-pdf-design-guideline-history>
            </div>
         </div>
      </div>
   </div>
</template>

<script lang="ts">
import { IContentBrickDefinition } from "@backend/api/pmToolApi";
import EventBus from "@backend/EventBus";
import Events from "@models/shared/Events";
import { Component, Prop, Vue } from "vue-property-decorator";
import ComponentBase from "@components/Shared/Base/component-base.vue";
import ExportPdfDesignGuidelineFields from "@components/ContentBricks/Export/export-pdf-design-guideline-fields.vue";
import ExportPdfDesignGuidelineMetadata from "@components/ContentBricks/Export/export-pdf-design-guideline-metadata.vue";
import ExportPdfDesignGuidelineHistory from "@components/ContentBricks/Export/export-pdf-design-guideline-history.vue";
import ExportPdfDesignGuidelineTags from "@components/ContentBricks/Export/export-pdf-design-guideline-tags.vue";

@Component({
   name: "ExportDesignGuideLineContainer",
   components: {
      ExportPdfDesignGuidelineFields,
      ExportPdfDesignGuidelineMetadata,
      ExportPdfDesignGuidelineHistory,
      ExportPdfDesignGuidelineTags,
   },
})
export default class ExportDesignGuidelineContainer extends ComponentBase {
   @Prop({ required: true })
   contentBrick!: IContentBrickDefinition;

   @Prop({ required: true })
   hasValueFields: boolean;

   @Prop({ required: true })
   mediaUrls: { [key: string]: string };

   loading: boolean = true;
   routeNames: string[] = ["dgl-export-pdf", "content-brick-field-type-decorator"];

   async mounted() {
      await this.loadTranslations();
      EventBus.$on(Events.LanguageChanged, this.loadTranslations);
   }

   async loadTranslations() {
      this.loading = true;
      await this.loadRouteTranslations(this.routeNames);
      this.loading = false;
   }

   async print() {
      let contents = document.getElementById("printable").innerHTML;
      let iFrame = document.createElement("iframe");
      let localStylesheet = '<link rel="stylesheet" href="/css/export-pdf.css" />';

      iFrame.name = "iFrame";
      iFrame.style.position = "absolute";
      iFrame.style.top = "-1000000px";

      document.body.appendChild(iFrame);

      let frameDoc = iFrame.contentWindow
         ? iFrame.contentWindow.document
         : iFrame.contentDocument.document || iFrame.contentDocument;

      frameDoc.open();
      frameDoc.write(`
        <html lang="en">
            <head>
                ${localStylesheet}
                <title>${this.contentBrick.code} ${this.contentBrick.name}</title>
            </head>
            <body>
                ${contents}
            </body>
        </html>
    `);
      frameDoc.close();

      // Wait for stylesheets to load
      await new Promise((resolve) => {
         let interval = setInterval(() => {
            if (iFrame.contentWindow.document.readyState === "complete") {
               clearInterval(interval);
               resolve();
               iFrame.contentWindow.focus();
               iFrame.contentWindow.print();
               document.body.removeChild(iFrame);
               this.$emit("export-done");
            }
         }, 50);
      });
   }
}
</script>

<style scoped></style>
