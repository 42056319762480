<template>
   <div>
      <h5>{{ translateKey("dglExportPdf.testProcedureLabel", translations) }}</h5>

      <table class="table">
         <tr>
            <th v-for="header in headers" :key="header.value" :style="header.style">
               {{ header.text }}
            </th>
         </tr>
         <tr v-for="item in flatFieldTree" :key="item.id">
            <!-- name -->
            <td :style="getRowStyle(item)">
               {{ item.name }}
            </td>
            <!-- group description -->
            <td v-if="isGroup(item)" colspan="3">
               <div v-html="item.description?.value"></div>
            </td>
            <!-- data type -->
            <td v-else>
               <span>{{ getItemType(item) }}</span>
            </td>
            <!-- unit -->
            <td>
               <template v-if="isList(item)">
                  <span
                     v-for="listItem in item.list.items.filter((x) => !x.disabled)"
                     :key="listItem.identifier"
                     class="tag"
                     :style="listItem.identifier === item.value ? 'font-weight: bold' : ''"
                  >
                     {{ listItem.text }}
                  </span>
               </template>
               <template v-else>{{ item.unit?.displayText }}</template>
            </td>
            <!-- value -->
            <td>
               <template v-if="isImage(item)">
                  <img
                     eager
                     v-for="imgSrc in item.value"
                     :key="imgSrc"
                     :src="mediaUrls[imgSrc]"
                     class="thumbnail"
                     @load="onImageLoaded(imgSrc)"
                  />
               </template>
               <template v-else-if="isVideo(item)">
                  <svg
                     v-if="item.value && item.value.length > 0"
                     height="24px"
                     viewBox="0 -960 960 960"
                     width="24px"
                     fill="#676767"
                  >
                     <path
                        d="M160-160q-33 0-56.5-23.5T80-240v-480q0-33 23.5-56.5T160-800h480q33 0 56.5 23.5T720-720v180l160-160v440L720-420v180q0 33-23.5 56.5T640-160H160Zm0-80h480v-480H160v480Zm0 0v-480 480Z"
                     />
                  </svg>
               </template>
               <template v-else-if="item.type == ContentBrickFieldType.TextArea">
                  <div v-html="item.value" />
               </template>
               <template v-else-if="item.type == ContentBrickFieldType.Decimal">
                  <span>{{ parseFloat(item.value).toFixed(2) }}</span>
               </template>
               <template v-else-if="isList(item)">
                  <span>{{ getListValue(item) }}</span>
               </template>
               <template v-else>
                  <span>{{ item.value }}</span>
               </template>
            </td>
         </tr>
      </table>
   </div>
</template>

<script lang="ts">
import {
   ContentBrickDefinition,
   ContentBrickDefinitionApi,
   ContentBrickFieldDefinition,
   ContentBrickFieldType,
   DesignGuidelineValueFieldDefinition,
   ImageType,
   TranslationPublicModel,
} from "@backend/api/pmToolApi";
import { Component, Prop, Vue } from "vue-property-decorator";
import ComponentBase from "@components/Shared/Base/component-base.vue";
import BaseResponse from "@models/BaseResponse";
import {
   ContentBrickTreeNodeTypeUtils as Util,
   FieldDefinition,
   FieldTreeNode,
} from "@models/shared/ContentBrickTreeNodeTypeUtils";
import { DataTableHeader } from "vuetify";
import ContentBrickUtils from "@utils/ContentBrickUtils";
import { ContentBrickFieldTypeDecorator } from "@models/shared/ContentBrickFieldTypeDecorator";
import { ImageTypeDecorator } from "@models/shared/imageTypeDecorator";

@Component
export default class ExportPdfDesignGuidelineFields extends ComponentBase {
   attachmentsLoadStatus: { [key: string]: boolean } = {};
   ContentBrickFieldType: any = ContentBrickFieldType;

   @Prop({ required: true })
   translations: TranslationPublicModel[];

   @Prop({ required: true })
   contentBrick: ContentBrickDefinition;

   @Prop({ default: false })
   hasValueFields: boolean;

   @Prop({ required: true })
   mediaUrls: { [key: string]: string };

   headers: any[] = [
      {
         text: "Name",
         value: "name",
         translationKey: "dglExportPdf.nameHeader",
         style: "width: 35%",
      },
      { text: "Data type", value: "type", translationKey: "dglExportPdf.dataTypeHeader", style: "width: 15%" },
      { text: "Unit", value: "unit", translationKey: "dglExportPdf.unitHeader", style: "width: 15%" },
      { text: "Value", value: "value", translationKey: "dglExportPdf.valueHeader", style: "width: 35%" },
   ];

   getRowStyle(item: FieldTreeNode): string {
      let padding = "padding-left: " + (item._depth - 1) * 16 + "px";
      let weight = this.isGroup(item) ? "font-weight: bold;" : "";
      return padding + ";" + weight;
   }

   mounted() {
      this.translateHeaders(this.headers, this.translations);

      let imageTypeDecorator = new ImageTypeDecorator(ImageType.Picture);
      let extensions = imageTypeDecorator.extensions.split(",").map((e) => e.trim().toLowerCase().replace("*.", ""));

      for (let key of Object.keys(this.mediaUrls)) {
         let extension = key.substring(key.lastIndexOf(".") + 1).toLowerCase();
         if (extensions.includes(extension)) {
            this.attachmentsLoadStatus[key] = false;
         }
      }

      if (!this.mediaUrls || Object.keys(this.attachmentsLoadStatus).length === 0) {
         this.$emit("loaded");
      }
   }

   onImageLoaded(blobName: string) {
      this.attachmentsLoadStatus[blobName] = true;
      if (Object.values(this.attachmentsLoadStatus).every((status) => status === true)) {
         this.$emit("loaded");
      }
   }

   // -------- Fields --------

   Util = Util;

   allFieldsInternal: FieldDefinition[] | null = null;

   get fields(): ContentBrickFieldDefinition[] {
      return this.contentBrick?.fields ? this.contentBrick.fields : [];
   }

   get valueFields(): DesignGuidelineValueFieldDefinition[] {
      return this.contentBrick?.designGuidelineValueFields ?? [];
   }

   get allFields(): FieldDefinition[] {
      if (!this.hasValueFields) {
         // not DGL -> only fields
         return this.fields;
      }

      if (!this.allFieldsInternal) {
         this.allFieldsInternal = this.fields.concat(this.valueFields).sort(Util.compareNodes);
      }

      return this.allFieldsInternal;
   }

   get flatFieldTree(): FieldTreeNode[] {
      let fields = this.allFields;
      const [, ...exceptRoot] = ContentBrickUtils.flattenDfsPreorder(
         this.contentBrick.contentBrickFieldGroupTree,
         null,
         0,
         [],
         fields
      );
      return exceptRoot;
   }

   isImage(item: DesignGuidelineValueFieldDefinition): boolean {
      return item.type === ContentBrickFieldType.Image;
   }

   isVideo(item: DesignGuidelineValueFieldDefinition): boolean {
      return item.type === ContentBrickFieldType.Video;
   }

   isGroup(node: FieldTreeNode): boolean {
      return Util.isGroup(node);
   }

   isList(item: FieldTreeNode): boolean {
      return (
         item.type == ContentBrickFieldType.List ||
         item.type == ContentBrickFieldType.MultiSelectList ||
         item.type == ContentBrickFieldType.ComboBox
      );
   }

   getItemType(item: FieldTreeNode): string | undefined {
      if (Util.isGroup(item)) return undefined;
      let decorator = new ContentBrickFieldTypeDecorator(item.type);
      return this.translateKey(decorator.translationKey, this.translations);
   }

   getListValue(item: FieldTreeNode): string | undefined {
      if (!item.value) return undefined;

      let arrayValue = Array.isArray(item.value) ? item.value : [item.value];
      let selectedItems = item.list.items.filter((x) => arrayValue.includes(x.identifier));
      if (selectedItems.length === 0) return undefined;

      return selectedItems.map((x) => x.text).join(", ");
   }
}
</script>

<style scoped></style>
