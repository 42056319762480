<template>
   <div>
      <v-menu v-model="isOpen" class="notification-list" offset-y>
         <template v-slot:activator="{ on }" :offset-x="true" :close-on-content-click="false" nudge-width="500">
            <v-badge color="red lighten-1" :content="notificationCount" overlap bottom :value="notificationCount > 0">
               <v-btn ui-test-data="top-bar-notifications-button" v-on="on" icon class="nav-menu-icon">
                  <v-icon>mdi-bell-ring</v-icon>
               </v-btn>
            </v-badge>
         </template>
         <div>
            <v-card width="480">
               <v-list dense v-if="notifications.length > 0">
                  <v-row>
                     <v-subheader class="ml-5">{{ translateKey("notificationsDialog.title", translations) }}</v-subheader>
                     <v-spacer></v-spacer>
                     <v-tooltip left>
                        <template v-slot:activator="{ on, attrs }">
                           <v-btn
                              small
                              class="mr-5 mt-1"
                              color="error"
                              elevation="0"
                              @click="deactivateAllNotificationsForUser()"
                              text
                              v-bind="attrs"
                              v-on="on"
                           >
                              <v-icon small class="mr-1">mdi-message-bulleted</v-icon>
                           </v-btn>
                        </template>
                        <span>{{ translateKey("notificationsDialog.readAllTooltip", translations) }}</span>
                     </v-tooltip>
                     <v-tooltip left>
                        <template v-slot:activator="{ on, attrs }">
                           <v-btn
                              small
                              class="mr-5 mt-1"
                              color="error"
                              elevation="0"
                              :to="`/NotificationHistory`"
                              text
                              v-bind="attrs"
                              v-on="on"
                           >
                              <v-icon small class="mr-1">mdi-history</v-icon>
                           </v-btn>
                        </template>
                        <span>{{ translateKey("notificationsDialog.historyTooltip", translations) }}</span>
                     </v-tooltip>
                  </v-row>

                  <v-list-item
                     v-for="item in notifications"
                     :key="item.id"
                     link
                     tag="span"
                     v-if="item.isActive"
                     class="green lighten-5"
                     @click="notificationClick(item)"
                     dense
                     :three-line="item.text.length > 56"
                  >
                     <v-list-item-avatar>
                        <v-icon>{{ item.icon ? item.icon : "mdi-message-bulleted" }}</v-icon>
                     </v-list-item-avatar>
                     <v-list-item-content>
                        <v-list-item-title>{{ item.name }} - {{ notificationCretedDate(item) }}</v-list-item-title>
                        <v-list-item-subtitle>
                           {{ item.text }}
                        </v-list-item-subtitle>
                     </v-list-item-content>
                     <v-tooltip left>
                        <template v-slot:activator="{ on, attrs }">
                           <v-list-item-action v-bind="attrs" v-on="on">
                              <v-btn icon @click.stop="removeNotification(item)">
                                 <v-icon small>mdi-comment-remove-outline</v-icon>
                              </v-btn>
                           </v-list-item-action>
                        </template>
                        <span>{{ translateKey("notificationsDialog.readTooltip", translations) }}</span>
                     </v-tooltip>
                  </v-list-item>

                  <v-subheader v-if="notifications.filter((obj) => !obj.isActive).length > 0">
                     {{ translateKey("notificationsDialog.readNotificationsTitle", translations) }}
                  </v-subheader>

                  <v-list-item
                     v-for="item in notifications"
                     :key="item.id"
                     link
                     tag="span"
                     v-if="!item.isActive"
                     @click="notificationClick(item)"
                     dense
                     :three-line="item.text.length > 56"
                  >
                     <v-list-item-avatar>
                        <v-icon>{{ item.icon ? item.icon : "mdi-message-bulleted" }}</v-icon>
                     </v-list-item-avatar>
                     <v-list-item-content>
                        <v-list-item-title>{{ item.name }} - {{ notificationCretedDate(item) }}</v-list-item-title>
                        <v-list-item-subtitle>
                           {{ item.text }}
                        </v-list-item-subtitle>
                     </v-list-item-content>
                     <v-list-item-action>
                        <v-btn icon @click.stop="removeNotification(item)">
                           <v-icon small>mdi-comment-remove-outline</v-icon>
                        </v-btn>
                     </v-list-item-action>
                  </v-list-item>

                  <v-spacer></v-spacer>
               </v-list>

               <v-row v-if="notifications.length < 1">
                  <v-subheader inset>
                     {{ translateKey("notificationsDialog.noNotificationsLabel", translations) }}
                  </v-subheader>
                  <v-spacer></v-spacer>
                  <v-btn small class="mr-5 mt-2" color="error" elevation="0" :to="`/NotificationHistory`">
                     {{ translateKey("notificationsDialog.historyButton", translations) }}
                  </v-btn>
               </v-row>
            </v-card>
         </div>
      </v-menu>
   </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import { NotificationApi, NotificationModel, NotificationType, TranslationPublicModel } from "@backend/api/pmToolApi";
import store from "@backend/store/authStore";
import Events from "@models/shared/Events";
import EventBus from "@backend/EventBus";
import axios from "axios";
import { AxiosKeycloak } from "@backend/axiosKeycloak";
import {
   HubConnection,
   HubConnectionBuilder,
   IHttpConnectionOptions,
   LogLevel,
   HubConnectionState,
   HttpTransportType,
} from "@microsoft/signalr";
import ComponentBase from "@components/Shared/Base/component-base.vue";

@Component({
   name: "NotificationList",
   components: {},
})
export default class NotificationList extends ComponentBase {
   @Prop({ required: true })
   translations: TranslationPublicModel[];

   connection: HubConnection;
   userId: string =
      store.getUserId() ??
      (() => {
         throw new Error("User Id not found in Store");
      })();

   created() {
      let keycloak = AxiosKeycloak.getKeycloak();

      const options: IHttpConnectionOptions = {
         accessTokenFactory: async () => {
            await keycloak.updateToken(30).catch(() => {
               console.error(
                  "Failed to update Keycloak access token",
                  keycloak,
                  keycloak.token,
                  keycloak.isTokenExpired()
               );
               throw new Error("Access token not found");
            });
            return keycloak.token!;
         },
         transport: HttpTransportType.LongPolling, //only possible transport right
      };
      this.connection = new HubConnectionBuilder()
         .withUrl(axios.defaults.baseURL + "/notificationhub", options)
         .configureLogging(LogLevel.Debug)
         .withAutomaticReconnect()
         .build();
   }

   retryAsync<TReturn>(
      delegate: () => Promise<TReturn>,
      friendlyName: string,
      handle: { interrupt: boolean } | undefined = undefined,
      retriesMs: Array<number> = [2000, 5000, 30000]
   ): Promise<TReturn | undefined> {
      const delay: (ms: number) => Promise<void> = (ms) => new Promise((res) => setTimeout(res, ms));

      return new Promise<TReturn | undefined>(async (resolve, reject) => {
         let error: any | undefined = undefined;
         let result: TReturn | undefined;
         let tryExecute: () => Promise<TReturn | undefined> = async () =>
            await delegate().catch((err: any) => (error = err));

         result = await tryExecute();

         for (const [index, delayMs] of retriesMs.entries()) {
            if (!error) break;
            await delay(delayMs);
            if (handle?.interrupt) {
               return reject(`'${friendlyName}' interrupted by client code`);
            }

            console.warn(`Retry (${index + 1}/${retriesMs.length}) ${friendlyName} due to failure: '${error}'`);
            result = await tryExecute();
         }

         if (error) {
            console.warn(`'${friendlyName}' ran out of retries, last error: ${error}`);
            reject(error); //rethrow the last error
         }

         resolve(result);
      });
   }

   interruptHandle: { interrupt: boolean } | undefined = undefined;
   connectNotificationsHub() {
      if (this.connection.state == HubConnectionState.Connected) {
         return;
      }

      // if any previous attempt is running, stop it
      if (this.interruptHandle) {
         this.interruptHandle.interrupt = true;
         this.interruptHandle = { interrupt: false };
      }

      this.retryAsync(
         () =>
            this.connection.start().then(() => {
               this.connection.invoke("ClientConnected");
            }),
         "Connect Notification Hub",
         this.interruptHandle
      ).catch((err) => {
         EventBus.$emit(Events.DisplayToast, {
            color: "error",
            text: `Failed to connect to Notifications hub: ${err}`,
         });
      });
   }

   async mounted() {
      this.connectNotificationsHub();

      this.connection.onclose((err: Error | undefined) => {
         console.log("Notifications disconned, retrying", err, JSON.stringify(err));
         this.connectNotificationsHub();
      });

      this.connection.on("ReceiveMessage", (notification: NotificationModel) => {
         if (notification.type === NotificationType.GdmAutoNodeRemoveNodeProgress) {
            EventBus.$emit(Events.GdmAutoNodeRemoveNodeProgressUpdated, notification);
            return; // only reroute progress event directly to dialog component (not persisted in notifications)
         }

         this.notifications.unshift({
            id: notification.id,
            icon: notification.type == NotificationType.Task ? "mdi-alert" : "mdi-message-bulleted",
            name: notification.name,
            text: notification.message,
            url: notification.url,
            created: notification.created,
            isActive: true,
         });
      });

      EventBus.$on(Events.DeactivateNotification, (item: NotificationModel) => {
         this.notifications = this.notifications.filter((x) => x.id != item.id);
      });

      EventBus.$on(Events.DeactivateAllNotifications, () => {
         this.notifications = [];
      });

      EventBus.$on(Events.ActivateNotification, (item: NotificationModel) => {
         this.notifications.unshift({
            id: item.id,
            icon: item.type == NotificationType.Task ? "mdi-alert" : "mdi-message-bulleted",
            name: item.name,
            text: item.message,
            url: item.url,
            created: item.created,
            isActive: true,
         });
      });

      EventBus.$on(Events.TokenUpdated, (item: NotificationModel) => {
         if (this.connection.state == HubConnectionState.Connected) {
            console.info("Keycloak token updated while Notifications hub is disconnected, retrying connection");
            this.connectNotificationsHub();
         }
      });
   }

   isOpen: boolean = false;

   notifications: NotificationModel[] = [];

   get notificationCount() {
      if (this.notifications.length > 0) return this.notifications.filter((x) => x.isActive == true).length;
      else 0;
   }

   notificationCretedDate(notification: NotificationModel) {
      return this.$moment(notification.created?.timestamp).format("yyyy-MM-DD HH:mm");
   }

   async notificationClick(item: NotificationModel) {
      item.isActive = false;

      this.notificationCount;

      await this.deactivateNotification(item);

      this.$router.push({ path: item.url }).catch(() => {});
   }

   async removeNotification(item: NotificationModel) {
      await this.deactivateNotification(item);
      this.notifications = this.notifications.filter((obj) => obj != item);
      this.notificationCount;
      EventBus.$emit(Events.DeactivateNotificationList, item);
   }

   async deactivateNotification(item: NotificationModel) {
      await NotificationApi.deactivateNotification(item.id);
   }

   async deactivateAllNotificationsForUser() {
      await NotificationApi.deactivateAllNotificationsForUser(this.userId);
      this.notifications = [];
      this.notificationCount;
      EventBus.$emit(Events.DeactivateAllNotificationsList);
   }
}
</script>

<style scoped>
.notification-list {
   z-index: 15;
   background-color: rgb(245, 245, 245) !important;
}

.inactive-item {
   background-color: rgb(245, 245, 245) !important;
}
</style>
