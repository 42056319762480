import {
   ContentNode,
   IProjectTask,
   ProjectContentBrick,
   ProjectTaskContent,
   PbbContentItemType,
   QueryDataModelType,
} from "@backend/api/pmToolApi";
import { ContentBrickTypeDecorator } from "@models/shared/ContentBrickTypeDecorator";
import TestResultTree from "@models/testReport/TestResultTree";
import { flatMapDeep } from "lodash";

export default class TaskUtils {
   public static getAttachmentModelType(contentBrick: ProjectContentBrick): QueryDataModelType {
      const contentDefinition = contentBrick.originatingContentDefinition!;

      if (contentDefinition.contentItemType === PbbContentItemType.Adhoc) return QueryDataModelType.Process;

      if (contentBrick.metaData?.saveOnlyInPdm) return QueryDataModelType.Process;

      return contentDefinition.queryContentFields!.queryModelType;
   }

   public static getReportAttachmentModelType(testResult: TestResultTree): QueryDataModelType {
      if (testResult.contentItemType === PbbContentItemType.Adhoc) return QueryDataModelType.Process;

      if (testResult.saveOnlyInPdm) return QueryDataModelType.Process;

      return testResult.modelType ?? QueryDataModelType.Domain;
   }

   public static getAttachmentPdmNodeId(
      task: IProjectTask,
      contentBrick: ProjectContentBrick,
      pdmNodeId: string | null
   ): string | null {
      const contentDefinition = contentBrick.originatingContentDefinition!;

      if (contentDefinition.contentItemType === PbbContentItemType.Adhoc)
         return task.id; /*pdmNodeId is null for ad-hoc*/

      if (contentBrick.metaData?.saveOnlyInPdm) return null;

      return pdmNodeId;
   }

   public static hasDesignGuidelines(content: ProjectTaskContent | undefined): boolean {
      return (
         content?.contentItems?.some((contentItem) =>
            contentItem.contentRoot?.some((contentNode) => {
               if (!contentNode) return;

               const flatten = (item: ContentNode) => [item, flatMapDeep(item?.children ?? [], flatten)];
               const flattenedTree = flatMapDeep([contentNode], flatten) as ContentNode[];

               return flattenedTree.some(
                  (cn) =>
                     cn.projectContentBrick?.metaData?.contentBrickType != null &&
                     ContentBrickTypeDecorator.get(cn.projectContentBrick.metaData.contentBrickType).hasValueFields
               );
            })
         ) ?? false
      );
   }
}
