<template>
   <v-list-item
      tag="span"
      :color="selectedColor"
      :key="item.uiSelector"
      class="ml-1"
      :ui-test-data="item.uiSelector"
      :to="item.location"
      :class="{ 'selected-item': isActive(item) }"
   >
      <v-tooltip right>
         <template v-slot:activator="{ on, attrs }">
            <v-list-item-icon v-bind="attrs" v-on="on">
               <v-icon :color="selectedColor">
                  {{ item.icon }}
               </v-icon>
            </v-list-item-icon>
         </template>
         <span>{{ translateKey(item.tooltipKey, translations) }}</span>
      </v-tooltip>
      <v-list-item-content>
         <v-list-item-title>
            {{ translateKey(item.labelKey, translations) }}
         </v-list-item-title>
      </v-list-item-content>
   </v-list-item>
</template>

<script lang="ts">
import { Component, Prop } from "vue-property-decorator";
import { TranslationPublicModel } from "@backend/api/pmToolApi";
import ComponentBase from "@components/Shared/Base/component-base.vue";
import { NavItem, NavItemLeaf } from "@components/NavMenu/navItems";

@Component({
   name: "NavItemLeafComponent",
})
export default class NavItemLeafComponent extends ComponentBase {
   @Prop({ required: true })
   item: NavItemLeaf;

   @Prop({ required: true })
   selectedColor: string;

   @Prop({ required: true })
   translations: TranslationPublicModel[];

   @Prop({ required: true })
   isActive: (x: NavItem) => boolean;
}
</script>

<style scoped>
.v-list-item--active {
   color: initial !important;
}
.selected-item {
   background-color: var(--v-bg240);
}

.v-list-item a {
   color: initial;
}
</style>
